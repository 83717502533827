import FileIcon from '@/components/icons/File.vue';

import Attachment from './Attachment';

export default class FileAttachment extends Attachment {
  constructor(file, i) {
    super(file, i);
    this.thumb = true;
    this.component = FileIcon;
  }
}
