<template>
  <div
    class="chat"
    :class="{ selected }"
  >
    <div class="person">
      <img
        :src="avatar"
        class="avatar"
      >
      <div class="text">
        <div class="name-wrapper">
          <span class="name">{{ name || '&nbsp;' }}</span>
          <VerifiedCheckIcon v-if="verified" />
        </div>
        <div
          v-if="text"
          class="last-message"
          :class="{'show-full': showFull}"
        >
          <PaperclipIcon v-if="hasAttachment" />
          {{ text }}
        </div>
      </div>
    </div>
    <div
      v-if="!hideInfo"
      class="date-info"
    >
      <ReadIcon v-if="read" />
      <UnreadIcon v-else />
      <div class="date">
        {{ formattedDate }}
      </div>
      <div
        v-if="counter > 0"
        class="unread"
      >
        {{ counter }}
      </div>
    </div>
  </div>
</template>

<script>
import formatDateTimeMixin from '@/mixins/formatDateTimeMixin';

import PaperclipIcon from '@/components/icons/Paperclip.vue';
import ReadIcon from '@/components/icons/Read.vue';
import UnreadIcon from '@/components/icons/Unread.vue';
import VerifiedCheckIcon from '@/components/icons/VerifiedCheck.vue';

export default {
  components: {
    PaperclipIcon,
    VerifiedCheckIcon,
    ReadIcon,
    UnreadIcon,
  },
  mixins: [formatDateTimeMixin],
  props: {
    selected: {
      type: Boolean,
    },
    avatar: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    verified: {
      type: Boolean,
    },
    text: {
      type: String,
      default: '',
    },
    showFull: {
      type: Boolean,
    },
    hasAttachment: {
      type: Boolean,
    },
    date: {
      type: [Date, null],
      default: null,
    },
    counter: {
      type: Number,
      default: 0,
    },
    read: {
      type: Boolean,
    },
    hideInfo: {
      type: Boolean,
    },
  },
  computed: {
    formattedDate() {
      if (this.date) {
        return this.formatDateNumNum(this.date);
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  display: block;
  position: relative;
  cursor: pointer;
  scroll-margin-top: 100px;

  &.selected {
    background: $clr-own-bubble;
  }

  .date-info {
    position: absolute;
    top: 7px;
    right: 8px;
    font: $font-rn-200;

    svg {
      color: var(--clr-brand);
    }

    .date {
      color: $clr-text-trietary;
      display: inline;
    }

    .unread {
      min-width: 16px;
      width: fit-content;
      height: 16px;
      border-radius: 11px;
      background-color: var(--clr-brand);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
      margin-left: auto;
      padding: 0 4px;
    }
  }

  .person {
    position: relative;
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    overflow: hidden;

    .avatar {
      min-width: 56px;
      width: 56px;
      height: 56px;
      margin-right: 16px;
      margin-left: 16px;
      border-radius: 12px;
    }

    .text {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 0;
      height: 100%;
      padding: 8px 0;
      box-sizing: border-box;
      border-bottom: 1px solid $clr-border-middle;

      .name-wrapper {
        font: $font-sbn-400;
        display: flex;
        align-items: center;
        width: calc(100% - 64px);

        .name {
          max-width: max-content;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        svg {
          margin-left: 8px;
          width: 16px;
          min-width: 16px;
          color: var(--clr-brand);
        }
      }

      .last-message {
        font: $font-rw-300;
        color: $clr-text-secondary;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        &.show-full {
          overflow: unset;
        }

        svg {
          vertical-align: bottom;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .chat {
    .date-info {
      top: 12px;
    }

    &.selected {
      background: none;
    }
  }
}
</style>
