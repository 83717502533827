<template>
  <div class="people">
    <Header
      :selected-category="selectedCategory"
      :selected-mode="selectedMode"
      @selectCategory="selectCategory"
      @selectMode="selectMode"
      @search="setChatsToSearch"
    />

    <div class="list">
      <ArchivedChatsButton
        v-if="selectedMode === 'normal' && selectedCategory === 'all'"
        :archived-chats="archivedChats"
        @click.native="selectedMode = 'archived'"
      />
      <component
        :is="components[selectedMode]"
        :list="list"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import ArchivedChats from './ArchivedChats.vue';
import ArchivedChatsButton from './ArchivedChatsButton.vue';
import ChatList from './ChatList.vue';
import Header from './Header/Index.vue';
import SearchResults from './SearchResults.vue';

import chatFromBackend from '@/models/dto/chatFromBackend';

export default {
  name: 'ChatsList',
  components: {
    ArchivedChatsButton,
    Header,
    ChatList,
    SearchResults,
    ArchivedChats,
  },
  data() {
    return {
      selectedCategory: 'dm',
      selectedMode: 'normal',
      searchResults: [],
      components: {
        normal: 'ChatList',
        search: 'SearchResults',
        archived: 'ArchivedChats',
      },
    };
  },
  computed: {
    ...mapState({
      archivedChats: (state) => state.api.messenger.archivedChats,
    }),
    ...mapGetters([
      'allChats',
    ]),
    msgSocket() {
      return this.$store.state.api.msgSocket;
    },
    chatsInSelectedCategory() {
      return this.allChats.filter((chat) => this.selectedCategory === 'all'
        || chat.type === this.selectedCategory
        || chat.type === 'support')
        .map(chatFromBackend);
    },
    list() {
      switch (this.selectedMode) {
        case 'search':
          return this.searchResults;
        case 'archived':
          return this.archivedChats;
        default:
          return this.chatsInSelectedCategory;
      }
    },
  },
  watch: {
    msgSocket: {
      handler() {
        if (this.msgSocket) {
          this.msgSocket.addEventListener('message', this.handleResponse);
        }
      },
      immediate: true,
    },
    $route(newRoute) {
      if (newRoute.query.tab) {
        this.selectedMode = 'normal';
        this.selectedCategory = newRoute.query.tab;
      }
    },
  },
  methods: {
    ...mapMutations({
      updateChatInState: 'updateChat',
      floatChat: 'floatChat',
    }),
    selectCategory(category) {
      this.selectedCategory = category;
    },
    selectMode(mode) {
      this.selectedMode = mode;
    },
    updateChat(chat) {
      this.floatChat(chat);
      this.updateChatInState(chat);
    },
    handleResponse(response) {
      if (!response.data) return;

      const handlers = {
        chat_update: this.updateChat,
      };

      const action = JSON.parse(response.data);
      const actionName = Object.keys(action)[0];

      setTimeout(() => {
        handlers[actionName]?.(action[actionName]); // eslint-disable-line 
      });
    },
    setChatsToSearch(results) {
      const hasAttachment = (message) => {
        const attachments = ['round', 'audio', 'file', 'model', 'call', 'geo', 'gallery'];
        let result = false;

        attachments.forEach((attachment) => {
          if (message[attachment]) {
            result = true;
          }
        });

        return result;
      };

      this.searchResults = results.map((result) => ({
        avatar: result.chat.avatar,
        name: result.chat.name,
        verified: result.chat.verified,
        text: result.message.text,
        hasAttachment: hasAttachment(result.message),
        date: result.message.created_at,
        read: result.message.read,
        longId: result.chat.id,
        messageId: result.message.id,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.people {
  border-right: 1px solid $clr-border-dark;
  position: relative;
  height: calc(100vh - #{$top-nav-height});
  padding-top: env(safe-area-inset-top);
  box-sizing: border-box;
  overflow-x: visible;
  background: white;

  .list {
    position: relative;
    height: calc(100% - 52px);
    overflow-y: scroll;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .people {
    height: calc(100% - #{$bottom-nav-height}) !important;
    border-right: none;

    .list {
      margin: 0;
    }
  }
}
</style>
