<template>
  <div class="categories">
    <button
      :class="{selected: selectedCategory === 'dm'}"
      @click="$emit('selectCategory', 'dm')"
    >
      {{ $t('messenger.dms') }}
      <UnreadBubble type="dm" />
    </button>
    <button
      :class="{selected: selectedCategory === 'group'}"
      @click="$emit('selectCategory', 'group')"
    >
      {{ $t('messenger.groups') }}
      <UnreadBubble type="group" />
    </button>
    <button
      :class="{selected: selectedCategory === 'channel'}"
      @click="$emit('selectCategory', 'channel')"
    >
      {{ $t('messenger.channels') }}
      <UnreadBubble type="channel" />
    </button>
    <button
      :class="{selected: selectedCategory === 'all'}"
      @click="$emit('selectCategory', 'all')"
    >
      {{ $t('messenger.all') }}
      <UnreadBubble />
    </button>
  </div>
</template>

<script>
import UnreadBubble from '@/components/UnreadBubble.vue';

export default {
  emits: ['selectCategory'],
  components: {
    UnreadBubble,
  },
  props: {
    selectedCategory: {
      type: String,
      default: 'dm',
    },
  },
};
</script>

<style lang="scss" scoped>
.categories {
  display: flex;
  justify-content: space-evenly;

  > * {
    margin-right: 8px;
    cursor: pointer;
    position: relative;
  }

  button {
    border-radius: 16px;
    border: none;
    background: $clr-20;
    padding: 6px 8px;
    color: $clr-text-trietary;
    font: $font-rn-200;

    &.selected {
      background: $grad-brand;
      color: white;
    }
  }

  .bubble {
    border: 1px solid white;
  }
}
</style>
