<template>
  <div class="chat">
    <div class="checkbox">
      <input
        v-model="checked"
        type="checkbox"
        :value="chat.id"
        @change="onChange"
      >
      <div class="checkmark" />
    </div>
    <MiniEntity :entity="chat" />
  </div>
</template>

<script>
import MiniEntity from '@/components/MiniEntity.vue';

export default {
  components: {
    MiniEntity,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      state: this.value,
    };
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        this.state = value;
      },
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.state);
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  display: flex;
  align-items: center;
  margin: 4px 0;
  gap: 8px;

  .entity {
    min-width: 0;
  }

  .checkbox {
    min-width: 20px;
  }
}
</style>
