<template>
  <div
    v-show="chatId"
    class="input-wrapper"
  >
    <InputUpper
      class="input-upper"
      :reply-target="replyTarget"
      :edit-target="editTarget"
      :attachments="attachments"
      @cancelReply="cancelReply"
      @cancelEdit="cancelEdit"
      @removeAttachment="removeAttachment"
    />

    <div class="input-row">
      <FileInput
        :can-attach="canAttach"
        @attachmentUpdated="handleAttachmentUpdate"
        @removeAttachment="removeAttachment"
      />
      <MsgInput
        ref="input"
        class="msg-input"
        :can-send="!!canSend"
        :mentions="subs"
        @input="handleInput"
        @send="send"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MsgInput from '@/components/MsgInput/Index.vue';

import chatTypeFromId from '../../chatTypeFromId';

import FileInput from './FileInput.vue';
import InputUpper from './InputUpper.vue';

import FileAttachment from '@/models/FileAttachment';
import ImageAttachment from '@/models/ImageAttachment';
import VideoAttachment from '@/models/VideoAttachment';

export default {
  emits: ['sentMessage', 'jinnyMessage'],
  name: 'MessageInput',
  components: {
    InputUpper,
    MsgInput,
    FileInput,
  },
  props: {
    subs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      replyTarget: undefined,
      editTarget: undefined,
      attachments: [],
      valueModel: '',
    };
  },
  computed: {
    ...mapGetters(['profile']),
    chatId() {
      return this.$route.params.chatId;
    },
    notOnlyWhitespace() {
      function onlyWhitespace(str) {
        return !str.replace(/\s/g, '').length;
      }

      return !onlyWhitespace(this.valueModel);
    },
    canSend() {
      return this.attachments.length ? this.attachmentLoaded : this.notOnlyWhitespace;
    },
    attachmentLoaded() {
      return this.attachments.length === 0
        || this.attachments.reduce((prev, curr) => prev && curr.id, true);
    },
    canAttach() {
      return !this.replyTarget && !this.editTarget && this.chatId !== 'jinny';
    },
    hasMentions() {
      return this.chatId && chatTypeFromId(this.chatId) !== 'dm' && this.chatId !== 'jinny';
    },
  },
  watch: {
    chatId() {
      this.cancelInput();
      this.removeAttachment();
    },
  },
  mounted() {
    this.$emitter.on('replyToMessage', (msg) => {
      this.startReply(msg);
    });

    this.$emitter.on('editMessage', (msg) => {
      this.startEdit(msg);
    });
  },
  methods: {
    ...mapActions({
      sendMessage: 'sendMessage',
      editMessage: 'editMessage',
      jinnySend: 'jinnySend',
    }),
    handleInput(value) {
      this.valueModel = value;
    },
    async send(messageText) {
      const handleJinnySend = (text) => {
        this.$emit('jinnyMessage', {
          id: new Date().valueOf(),
          chat_id: this.chatId,
          text,
          author_id: this.profile.id,
        });

        this.jinnySend(text).then((response) => {
          this.$emit('jinnyMessage', {
            id: new Date().valueOf(),
            chat_id: this.chatId,
            text: response.text,
            author_id: this.chatId,
          });
        });
      };

      const handleNormalSend = (text) => {
        const files = this.attachments.filter((attachment) => attachment instanceof FileAttachment);
        const media = this.attachments.filter(
          (attachment) => attachment instanceof ImageAttachment
           || attachment instanceof VideoAttachment,
        );
        const fileIds = files.map((attachment) => attachment.id);
        const galleryIds = media.map((attachment) => attachment.id);
        const messages = [];

        const msgTemplate = {
          chat_id: this.chatId,
          text,
          replied_id: this.replyTarget?.id,
        };

        if (galleryIds.length === 0) {
          messages.push({
            ...msgTemplate,
            file_id: fileIds.shift(),
          });
        } else {
          messages.push({
            ...msgTemplate,
            gallery_ids: galleryIds,
          });
        }

        fileIds.forEach((id) => {
          messages.push({
            chat_id: this.chatId,
            file_id: id,
          });
        });

        messages.forEach((message) => {
          this.sendMessage(message);
          this.$emit('sentMessage', message);
        });
      };

      const handleEdit = (text) => {
        this.editMessage({
          chatId: this.chatId,
          text,
          messageId: this.editTarget.id,
        });
        this.cancelEdit();
      };

      if (this.canSend) {
        if (this.editTarget) {
          handleEdit(messageText);
        } else if (this.chatId === 'jinny') {
          handleJinnySend(messageText);
        } else {
          handleNormalSend(messageText);
        }

        this.cancelReply();
        this.removeAttachment();
      }
    },
    cancelInput() {
      this.cancelReply();
      this.cancelEdit();
    },
    startReply(msg) {
      this.replyTarget = msg;
      this.$refs.input.focus();
    },
    cancelReply() {
      this.replyTarget = undefined;
    },
    startEdit(msg) {
      this.editTarget = msg;
      this.message = msg.text;
    },
    cancelEdit() {
      this.editTarget = undefined;
      this.message = '';
    },
    removeAttachment(i) {
      if (i !== undefined) {
        this.$delete(this.attachments, i);
      } else {
        this.attachments = [];
      }

      this.$emitter.emit('cancelAttachmentUpload');
    },
    handleAttachmentUpdate(attachment) {
      this.$set(this.attachments, attachment.index, attachment);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  z-index: 10;
  position: relative;
  width: 761px;
  background-color: white;
  box-sizing: border-box;

  .input-upper {
    padding-top: 8px;
  }

  .input-row {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: calc(100% - 64px);
    left: 50%;
    transform: translateX(-50%);

    .msg-input {
      margin: 8px 0 16px 0;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .input-wrapper {
    width: 100%;
  }
}
</style>
