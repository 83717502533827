<template>
  <div>
    <div
      v-if="replyTarget"
      class="reply-info"
    >
      <div class="action-body">
        <div class="action-name">
          {{ $t("messenger.replying_to") }} {{ replyTarget.author.name }}
        </div>
        <div
          class="text"
          :class="{ clamped }"
          @click="clamped = !clamped"
        >
          {{ replyTarget.text }}
        </div>
      </div>
      <span
        class="cancel"
        @click="$emit('cancelReply')"
      >
        <CrossIcon />
      </span>
    </div>
    <div
      v-if="editTarget"
      class="edit-info"
    >
      <div class="action-body">
        <div class="action-name">
          {{ $t("messenger.editing") }}
        </div>
        <div
          class="text"
          :class="{ clamped }"
          @click="clamped = !clamped"
        >
          {{ editTarget.text }}
        </div>
      </div>
      <span
        class="cancel"
        @click="$emit('cancelEdit')"
      >
        <CrossIcon />
      </span>
    </div>

    <div class="attach-previews">
      <div
        v-for="attachment in existingAttachments"
        :key="attachment.file.name"
        class="attach-preview"
      >
        <div class="wrapper">
          <component
            :is="attachment.component"
            :src="attachment.thumb"
            class="thumb"
          />
          <div
            v-if="!attachment.id"
            class="progress"
          >
            <div
              v-if="attachment.progress"
              class="fill"
              :style="`right: calc(${
                100 - (attachment.progress.loaded / attachment.progress.total) * 100
              }% + 1px)`"
            />
          </div>
          <div class="filename">
            {{ attachment.file.name }}
          </div>
          <div
            class="delete supel"
            @click="$emit('removeAttachment', attachment.index)"
          >
            <CrossIcon />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrossIcon from '@/components/icons/Cross.vue';
import FileIcon from '@/components/icons/File.vue';

import FileAttachment from '@/models/FileAttachment';
import ImageAttachment from '@/models/ImageAttachment';
import VideoAttachment from '@/models/VideoAttachment';

export default {
  emits: ['removeAttachment', 'cancelEdit', 'cancelReply'],
  components: {
    CrossIcon,
    FileIcon,
  },
  props: {
    replyTarget: {
      type: Object,
      default: () => undefined,
    },
    editTarget: {
      type: Object,
      default: () => undefined,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      clamped: true,
    };
  },
  computed: {
    existingAttachments() {
      return this.attachments.filter((attachment) => attachment);
    },
  },
  watch: {
    editTarget() {
      this.clamped = true;
    },
    replyTarget() {
      this.clamped = true;
    },
  },
  beforeMount() {
    this.ImageAttachment = ImageAttachment;
    this.VideoAttachment = VideoAttachment;
    this.FileAttachment = FileAttachment;
  },
};
</script>

<style lang="scss" scoped>
.reply-info,
.edit-info {
  margin-left: 32px;
  border-left: 2px solid var(--clr-brand);
  padding: 0 8px;
  font: $font-rn-300;
  position: relative;
  display: flex;
  justify-content: space-between;

  .action-body {
    min-width: 0;

    .action-name {
      color: var(--clr-brand);
    }

    .text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &.clamped {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }

  .cancel {
    flex-shrink: 0;
    margin-left: 8px;
    padding: 8px;
    cursor: pointer;
  }
}

.attach-previews {
  display: flex;
  flex-wrap: wrap;
  padding: 0 32px;

  .attach-preview {
    margin-top: 8px;
    margin-right: 16px;

    .wrapper {
      position: relative;
      width: 64px;

      img {
        object-fit: cover;
        object-position: center;
      }

      .thumb {
        width: 100%;
        height: 64px;
        border-radius: 15px;
        object-fit: cover;

        &.file {
          padding: 8px;
          box-sizing: border-box;
        }
      }

      .progress {
        position: absolute;
        bottom: 20%;
        left: 4px;
        right: 4px;
        height: 8px;
        background: rgba($clr-10, 0.6);
        border-radius: 5px;

        .fill {
          position: absolute;
          top: 1px;
          left: 1px;
          border-radius: 3px;
          height: 6px;
          right: 100%;
          background: $grad-brand;
        }
      }

      .filename {
        text-align: center;
        color: $clr-text-secondary;
        font: $font-rn-200;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // line-break: anywhere;
      }

      .delete {
        position: absolute;
        top: 0;
        right: 0;
        padding: 6px;
        background: $grad-brand;
        transform: translate(30%, -30%);
        cursor: pointer;

        svg {
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .reply-info,
  .edit-info {
    left: 0;
    width: calc(100% - 64px);
    margin: auto;

    .cancel {
      right: 16px;
    }
  }
}
</style>
