var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"appear"}},[(_vm.show)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
      handler: _vm.close,
      events: ['click', 'contextmenu'],
    }),expression:"{\n      handler: close,\n      events: ['click', 'contextmenu'],\n    }"}],ref:"actions",staticClass:"actions",class:{ own: _vm.own },style:({
      left: _vm.position.x + 'px',
      top: _vm.position.y + 'px',
    })},[(_vm.canReply)?_c('div',{on:{"click":_vm.reply}},[_c('div',{staticClass:"icon"},[_c('ShareIcon',{staticClass:"reply"})],1),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t('messenger.reply'))+" ")])]):_vm._e(),_c('div',{on:{"click":function($event){return _vm.$emit('forward')}}},[_c('div',{staticClass:"icon"},[_c('ShareIcon')],1),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t('messenger.forward'))+" ")])]),(_vm.canPin)?_c('div',{on:{"click":_vm.togglePin}},[_c('div',{staticClass:"icon"},[_c('PinIcon')],1),(_vm.message.pinned)?_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t('messenger.unpin'))+" ")]):_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t('messenger.pin'))+" ")])]):_vm._e(),(_vm.editable)?_c('div',{staticClass:"edit",on:{"click":_vm.edit}},[_c('div',{staticClass:"icon"},[_c('EditIcon')],1),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t('messenger.edit'))+" ")])]):_vm._e(),(_vm.own)?_c('div',{staticClass:"delete",on:{"click":function($event){return _vm.$emit('delete')}}},[_c('div',{staticClass:"icon"},[_c('TrashIcon')],1),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t('messenger.delete'))+" ")])]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }