function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 B';

  const k = 1024;
  const dm = Math.max(0, decimals);
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat(bytes / k ** i).toFixed(dm)} ${sizes[i]}`;
}

export default class Attachment {
  id = null;

  progress = null;

  constructor(file, i) {
    this.index = i;
    this.file = file;
    this.fileSizeFormatted = formatBytes(file?.size);
  }

  init() {
    return this;
  }

  setId(id) {
    this.id = id;
    return this;
  }
}
