<template>
  <div class="date">
    <div class="bubble">
      {{ createdAt }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    createdAt: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.date {
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  padding-bottom: 16px;
  z-index: 100;

  .bubble {
    background: rgba($clr-20, .7);
    border-radius: 16px;
    padding: 4px 12px;
    font: $font-rn-300;
  }
}
</style>
