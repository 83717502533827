<template>
  <div
    class="archived"
    @click="$emit('exit')"
  >
    <ArrowIcon />
  </div>
</template>

<script>
import ArrowIcon from '@/components/icons/Arrow.vue';

export default {
  emits: ['exit'],
  components: {
    ArrowIcon,
  },
};
</script>

<style lang="scss" scoped>
.archived {
  display: flex;
  align-items: center;
  height: 28px;
  cursor: pointer;
}
</style>
