var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 300,
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 300,\n  }"}],staticClass:"wrapper",class:{ own: _vm.own }},[_vm._t("default"),_c('div',{staticClass:"pre-msg"},[(_vm.firstUnread)?_c('div',{staticClass:"unread-line"},[_c('hr'),_c('span',[_vm._v(" "+_vm._s(_vm.$t('messenger.unread'))+" ")]),_c('hr')]):_vm._e()]),(_vm.showSender)?_c('router-link',{staticClass:"avatar-link",attrs:{"to":_vm.message.author.link}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.message.author.avatar}})]):_vm._e(),_c('div',{staticClass:"message",class:{
      firstUnread: _vm.firstUnread,
      selected: _vm.selected,
      highlighted: _vm.highlighted,
      'emoji-only': _vm.emojiOnly,
    },on:{"contextmenu":function($event){$event.preventDefault();return _vm.openActions.apply(null, arguments)},"click":_vm.handleClick}},[(_vm.showSender)?_c('router-link',{staticClass:"sender",attrs:{"to":_vm.message.author.link}},[_vm._v(" "+_vm._s(_vm.message.author.name)+" ")]):_vm._e(),_c('Actions',{ref:"actions",attrs:{"message":_vm.message,"messages-rect":_vm.messagesRect,"chat":_vm.selectedChat},on:{"delete":function($event){return _vm.$emit('delete')},"forward":function($event){return _vm.$emit('forward')},"closed":_vm.handleActionsClose}}),(!_vm.noMedia)?_c('Attachments',{staticClass:"attachments",attrs:{"message":_vm.message}}):_vm._e(),_c('div',{staticClass:"text",class:{ inline: _vm.short && _vm.noMedia && !_vm.emojiOnly },domProps:{"innerHTML":_vm._s(_vm.highlightedMessage)}}),_c('div',{staticClass:"mini-info",class:{ inline: _vm.short && _vm.noMedia && !_vm.emojiOnly }},[(_vm.message.edited)?_c('EditIcon',{staticClass:"edited-icon"}):_vm._e(),(_vm.message.read)?_c('ReadIcon'):_c('UnreadIcon'),_vm._v(" "+_vm._s(_vm.sentTime)+" ")],1),(_vm.message.unconfirmed)?_c('div',{staticClass:"unconfirmed"},[_c('ClockIcon')],1):_vm._e()],1),(_vm.highlighted)?_c('div',{staticClass:"search-result-text"},[_vm._v(" "+_vm._s(_vm.$t('messenger.search_result'))+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }