<template>
  <div
    class="button"
    @click="archive"
  >
    <div class="supel">
      <ArchiveIcon />
    </div>
    {{
      chat.archived
        ? $t("messenger.unarchive")
        : $t("messenger.to_archive")
    }}
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import linkToChatMixin from '@/mixins/linkToChatMixin';

import ArchiveIcon from '@/components/icons/Archive.vue';

import Chat from '@/models/Chat';

import './button.scss';

export default {
  emits: ['unarchived'],
  components: {
    ArchiveIcon,
  },
  mixins: [linkToChatMixin],
  props: {
    chat: {
      type: Chat,
      required: true,
    },
  },
  methods: {
    ...mapActions(['archiveChat']),
    archive() {
      this.archiveChat(!this.chat.archived);
      if (this.chat.archived) {
        this.$emit('unarchived');
        this.$router.replace({
          name: 'Messenger',
          params: {
            chatId: this.getLongId(this.chat),
          },
          query: {
            tab: 'all',
          },
        });
      } else {
        this.$router.replace({ name: 'Messenger' });
      }
    },
  },
};
</script>
