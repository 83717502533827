<template>
  <div
    v-if="geo"
    class="geo"
  >
    <GmapMap
      :center="geo"
      :zoom="7"
      style="width: 100%; height: 100%;"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
      }"
    >
      <GmapMarker
        :position="geo"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: {
    geo: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.geo {
  padding-top: 8px;
  max-width: 100%;
  width: 500px;
  height: 300px;

  a {
    color: var(--clr-brand);
    text-decoration: underline;
  }
}
</style>
