<template>
  <div
    v-if="!lastAdmin"
    class="button"
    @click="joinOrLeave"
  >
    <div class="supel">
      <PlusIcon />
    </div>
    {{
      chat.subscriber
        ? $t("messenger.leave")
        : $t("messenger.join")
    }}
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import linkToChatMixin from '@/mixins/linkToChatMixin';

import PlusIcon from '@/components/icons/Plus.vue';

import './button.scss';

export default {
  emits: ['exit'],
  components: {
    PlusIcon,
  },
  mixins: [linkToChatMixin],
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lastAdmin() {
      return this.chat.admin && this.chat.subs.filter((sub) => sub.admin).length === 1;
    },
  },
  methods: {
    ...mapActions(['joinChannel', 'deleteChat']),
    joinOrLeave() {
      if (this.chat.subscriber) {
        this.deleteChat(this.getLongId(this.chat));
        if (this.chat.type === 'group') {
          this.$emit('exit');
        }
      } else {
        this.joinChannel({ id: this.chat.id });
      }
      this.chat.subscriber = !this.chat.subscriber;
    },
  },
};
</script>
