<template>
  <Modal ref="modal">
    <div v-if="room">
      <Info
        :room="room"
        @updated="close"
      />
      <SubscriberList
        v-if="showSubs"
        :room="room"
      />
    </div>
    <div
      v-else
      class="loading"
    >
      <SpinnerIcon />
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';

import SpinnerIcon from '@/components/icons/Spinner.vue';

import Modal from '@/components/Modal.vue';

import Info from './Info.vue';
import SubscriberList from './SubscriberList.vue';

export default {
  name: 'DetailsModal',
  components: {
    Modal,
    Info,
    SubscriberList,
    SpinnerIcon,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showSubs() {
      return this.room.type === 'group' || this.room.admin;
    },
  },
  methods: {
    ...mapActions(['getGroup', 'getChannel']),
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
}
</style>
