import Channel from './Channel';
import Chat from './Chat';
import Dm from './Dm';
import Group from './Group';
import User from './User';

export default function chatFactory(chat) {
  let dm;

  switch (chat.type) {
    case 'dm':
      dm = new Dm(chat);
      if (chat.partner) {
        dm.partner = new User(chat.partner);
      }
      return dm;
    case 'group':
      return new Group(chat);
    case 'channel':
      return new Channel(chat);
    default:
      return new Chat(chat);
  }
}
