<template>
  <Modal ref="modal">
    <div class="modal-content flex">
      <button
        class="btn secondary"
        @click="deleteMsg(false)"
      >
        {{ $t("messenger.delete") }}
      </button>
      <button
        v-if="deletable"
        class="btn secondary"
        @click="deleteMsg(true)"
      >
        {{ $t("messenger.delete_for_everyone") }}
      </button>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';

import Modal from '@/components/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    deleteTarget: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    chatId() {
      return this.$route.params.chatId;
    },
    deletable() {
      if (this.deleteTarget) {
        const sinceCreatedMs = new Date() - new Date(this.deleteTarget.created_at);
        const sinceCreatedHours = sinceCreatedMs / 1000 / 60 / 60;
        return this.deleteTarget.own && sinceCreatedHours < 24;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(['deleteMessage']),
    open() {
      this.$refs.modal.open();
    },
    deleteMsg(forAll) {
      this.deleteMessage({
        chatId: this.chatId,
        messageId: this.deleteTarget.id,
        forAll,
      });
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  justify-content: space-between;
  margin: 32px -8px;

  > * {
    margin: 0 8px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .modal-content {
    margin-top: 64px;
  }
}
</style>
