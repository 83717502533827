<template>
  <transition name="appear">
    <div
      v-if="show"
      ref="actions"
      v-click-outside="{
        handler: close,
        events: ['click', 'contextmenu'],
      }"
      class="actions"
      :class="{ own }"
      :style="{
        left: position.x + 'px',
        top: position.y + 'px',
      }"
    >
      <div
        v-if="canReply"
        @click="reply"
      >
        <div class="icon">
          <ShareIcon class="reply" />
        </div>
        <span class="name">
          {{ $t('messenger.reply') }}
        </span>
      </div>

      <div @click="$emit('forward')">
        <div class="icon">
          <ShareIcon />
        </div>
        <span class="name">
          {{ $t('messenger.forward') }}
        </span>
      </div>

      <div
        v-if="canPin"
        @click="togglePin"
      >
        <div class="icon">
          <PinIcon />
        </div>
        <span
          v-if="message.pinned"
          class="name"
        >
          {{ $t('messenger.unpin') }}
        </span>
        <span
          v-else
          class="name"
        >
          {{ $t('messenger.pin') }}
        </span>
      </div>

      <div
        v-if="editable"
        class="edit"
        @click="edit"
      >
        <div class="icon">
          <EditIcon />
        </div>
        <span class="name">
          {{ $t('messenger.edit') }}
        </span>
      </div>

      <div
        v-if="own"
        class="delete"
        @click="$emit('delete')"
      >
        <div class="icon">
          <TrashIcon />
        </div>
        <span class="name">
          {{ $t('messenger.delete') }}
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { mapActions, mapGetters } from 'vuex';

import linkToChatMixin from '@/mixins/linkToChatMixin';

import EditIcon from '@/components/icons/Edit.vue';
import PinIcon from '@/components/icons/Pin.vue';
import ShareIcon from '@/components/icons/Share.vue';
import TrashIcon from '@/components/icons/Trash.vue';

import Chat from '@/models/Chat';
import Message from '@/models/Message';

export default {
  emits: ['closed', 'delete', 'forward'],
  name: 'Actions',
  components: {
    ShareIcon,
    EditIcon,
    TrashIcon,
    PinIcon,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [linkToChatMixin],
  props: {
    message: {
      type: Message,
      required: true,
    },
    chat: {
      type: Chat,
      required: true,
    },
    messagesRect: {
      // type: DOMRect,
      type: null,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      position: {
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    ...mapGetters(['profile']),
    own() {
      return this.message.author.id === this.profile.id;
    },
    editable() {
      const date = this.message.createdAt;
      const now = new Date();
      const diffTime = Math.abs(date - now);
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return this.own && !this.message.forwarded && !this.message.call && diffDays <= 1;
    },
    canReply() {
      return this.chat.type !== 'channel' || this.chat.admin;
    },
    canPin() {
      return this.chat.type === 'dm' || this.chat.admin;
    },
  },
  methods: {
    ...mapActions(['pinMessage']),
    open(event) {
      const reposition = () => {
        const rect = this.$refs.actions.getBoundingClientRect();

        const overlap = {
          x: rect.x + rect.width - (this.messagesRect.x + this.messagesRect.width),
          y: rect.y + rect.height - (this.messagesRect.y + this.messagesRect.height),
        };

        this.position.x -= overlap.x >= 0 ? rect.width : 0;
        this.position.y -= overlap.y >= 0 ? rect.height : 0;
      };

      this.show = true;

      this.position = {
        x: event.clientX,
        y: event.clientY,
      };

      this.$nextTick(reposition);
    },
    close() {
      this.show = false;
      this.$emit('closed');
    },
    reply() {
      this.$emitter.emit('replyToMessage', this.message);
      this.close();
    },
    edit() {
      this.$emitter.emit('editMessage', this.message);
      this.close();
    },
    togglePin() {
      this.message.pinned = !this.message.pinned;
      this.pinMessage({
        chatId: this.getLongId(this.chat),
        id: this.message.id,
        pinned: this.message.pinned,
      });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 100;
  background: white;
  border: 1px solid $clr-border-light;
  border-radius: 20px;
  padding: 8px 0;
  margin-bottom: 12px;
  font: $font-rn-300;
  box-shadow: $shadow-3;

  > * {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 12px;

    &:hover {
      background: $clr-20;
    }
  }

  .icon {
    cursor: pointer;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin: 4px 8px 4px 0;

    svg {
      width: 16px;
      height: 16px;

      &.reply {
        transform: scaleX(-1);
      }
    }
  }

  &.own {
    right: unset;

    &.appear-enter, &.appear-leave-to {
      opacity: 0;
    }
  }

  &.appear-enter-active, &.appear-leave-active {
    transition: opacity .3s;
    z-index: 1;
  }

  &.appear-enter, &.appear-leave-to {
    opacity: 0;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .actions {
    .icon {
      background: $clr-20;
    }
  }
}
</style>
