<template>
  <div
    v-if="audio"
    class="voice"
  >
    <div
      class="play supel"
      @click.stop="toggle"
    >
      <PauseIcon v-if="playing" />
      <PlayIcon v-else />
    </div>
    <div class="right">
      <audio
        ref="audio"
        :src="audio.url"
        @loadedmetadata="setDuration"
        @ended="playing = false"
      />
      <av-waveform
        ref-link="audio"
        :canv-width="200"
        :canv-height="100"
        :played-line-width="4"
        :played-line-color="playedColor"
        :noplayed-line-color="noplayedColor"
        :noplayed-line-width="4"
        :playtime="false"
        :playtime-slider-color="'transparent'"
        canv-class="waveform"
      />
      <div class="duration">
        {{ durationDisplay }}
      </div>
    </div>
  </div>
</template>

<script>
import PauseIcon from '@/components/icons/Pause.vue';
import PlayIcon from '@/components/icons/Play.vue';

import lightenDarkenColor from './lightenDarkenColor';

export default {
  components: {
    PlayIcon,
    PauseIcon,
  },
  props: {
    audio: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    const rootStyle = getComputedStyle(document.documentElement);

    return {
      playing: false,
      duration: undefined,
      playedColor: rootStyle.getPropertyValue('--clr-brand'),
      noplayedColor: lightenDarkenColor(rootStyle.getPropertyValue('--clr-brand'), 64),
    };
  },
  computed: {
    durationDisplay() {
      if (this.duration !== undefined) {
        return new Date(this.duration * 1000).toISOString().substr(14, 5);
      }
      return undefined;
    },
  },
  methods: {
    toggle() {
      if (this.$refs.audio.paused) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
      this.playing = !this.$refs.audio.paused;
    },
    setDuration() {
      this.duration = this.$refs.audio?.duration;
    },
  },
};
</script>

<style lang="scss">
audio {
  display: none;
}

.waveform {
  transform: scaleY(1);
  cursor: pointer;
  mask: url('~@/assets/voiceMask.svg');
}
</style>

<style lang="scss" scoped>
.voice {
  padding: 0 16px;
  display: flex;

  .play {
    background: $grad-brand;
    padding: 12px;
    margin-right: 16px;
    cursor: pointer;
    width: 48px;
    box-sizing: border-box;

    svg {
      color: white;
    }
  }

  .right {
    position: relative;

    .duration {
      position: absolute;
      bottom: 0;
      font: $font-sbw-300;
      color: var(--clr-brand);
      bottom: 8px;
    }
  }
}
</style>
