<template>
  <div
    v-if="chat"
    class="header-wrapper"
  >
    <div class="header">
      <ArrowIcon
        class="exit"
        @click.native="exit"
      />
      <span
        class="name btn"
        @click="sourceAction"
      >
        {{ chatName }}
      </span>
      <div
        class="user"
      >
        <a
          v-if="chat.id !== 'jinny' && chat.type === 'dm'"
          href="https://calls.arround.world"
          target="_blank"
          class="call"
        >
          <PhoneIcon />
        </a>
        <span
          v-if="chat.id !== 'jinny'"
          class="more"
          @click="toggleControls"
        >
          <MoreIcon />
        </span>
        <span
          class="btn"
          @click="sourceAction"
        >
          <img
            class="avatar supel"
            :src="chatAvatar || ''"
          >
        </span>
      </div>
    </div>

    <transition name="slide">
      <div
        v-if="controls"
        class="dropdown"
      >
        <ArchiveButton
          :chat="chat"
          @unarchived="$emit('unarchived')"
        />
        <MuteButton
          :chat="chat"
        />
        <JoinLeaveButton
          v-if="['channel', 'group'].includes(chat.type)"
          :chat="chat"
          @exit="exit"
        />
      </div>
    </transition>

    <DetailsModal
      ref="detailsModal"
      :room="chat"
    />
  </div>
</template>

<script>

import ArrowIcon from '@/components/icons/Arrow.vue';
import MoreIcon from '@/components/icons/More.vue';
import PhoneIcon from '@/components/icons/Phone.vue';

import ArchiveButton from './ArchiveButton.vue';
import DetailsModal from './DetailsModal/Index.vue';
import JoinLeaveButton from './JoinLeaveButton.vue';
import MuteButton from './MuteButton.vue';

import Chat from '@/models/Chat';

export default {
  name: 'ChatHeader',
  emits: ['unarchived'],
  components: {
    ArrowIcon,
    MoreIcon,
    PhoneIcon,
    DetailsModal,
    ArchiveButton,
    MuteButton,
    JoinLeaveButton,
  },
  props: {
    chat: {
      type: Chat,
      default: () => (new Chat({ avatar: 'img/profile-avatar-default.svg' })),
    },
  },
  data() {
    return {
      controls: false,
    };
  },
  computed: {
    selectedChatId() {
      return this.$route.params.chatId;
    },
    sourceAction() {
      switch (this.chat.type) {
        case 'dm':
          return () => {
            this.$router.push({ name: 'Profile', params: { id: this.chat.id } });
          };
        case 'group':
          return () => {
            this.$refs.detailsModal.open();
          };
        case 'channel':
          return () => {
            this.$refs.detailsModal.open();
          };
        case 'support':
          return () => {
            this.$router.push({ name: 'Profile', params: { id: 'zhara' } });
          };
        default:
          return () => {};
      }
    },
    chatName() {
      if (this.chat.partner) {
        return this.chat.partner.name;
      }
      return this.chat.name;
    },
    chatAvatar() {
      if (this.chat.partner) {
        return this.chat.partner.avatar;
      }
      return this.chat.avatar;
    },
  },
  watch: {
    selectedChatId() {
      this.controls = false;
    },
  },
  methods: {
    exit() {
      this.$emitter.emit('showNav');
      this.$router.push('/messenger');
    },
    toggleControls() {
      this.controls = !this.controls;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  .header {
    position: relative;
    background-color: white;
    z-index: 250;
    top: 0;
    width: 100%;
    height: 46px;
    max-height: 46px;
    padding: 12px 16px;
    padding-top: calc(12px + env(safe-area-inset-top));
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .name {
      font: $font-sbn-400;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .exit,
    .user {
      position: relative;
      padding-top: env(safe-area-inset-top);
      cursor: pointer;
    }

    .exit {
      min-width: 32px;
      padding: 8px;
      padding-left: 0;
    }

    .user {
      display: flex;
      align-items: center;

      .more, .call {
        padding: 8px 16px;
        display: flex;
      }

      .more {
        svg {
          transform: rotate(90deg);
        }
      }

      .avatar {
        width: 36px;
        height: 36px;
      }
    }
  }

  .dropdown {
    position: absolute;
    width: 100%;
    top: 46px;
    left: 0;
    display: flex;
    justify-content: space-evenly;
    padding: 16px 8px 8px 8px;
    z-index: 200;
    box-sizing: border-box;
    border-radius: 0px 0px 25px 25px;
    background: white;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.5s;
  }

  .slide-enter,
  .slide-leave-to {
    transform: translateY(-100%);
  }
}

@media screen and (min-width: $mobile-breakpoint) {
  .header-wrapper {
    .header {
      position: relative;

      .exit {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .header-wrapper > .header {
    .user {
      .more, .call {
        padding: 8px;
      }
    }
  }
}
</style>
