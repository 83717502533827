<template>
  <a
    v-if="file"
    class="file"
    :href="file.url"
    download
  >
    <FileIcon />
    <div class="info">
      <span>{{ file.name }}</span>
      <span class="file-size">{{ fileAttachment.fileSizeFormatted }}</span>
    </div>
  </a>
</template>

<script>
import FileIcon from '@/components/icons/File.vue';

import FileAttachment from '@/models/FileAttachment';

export default {
  components: {
    FileIcon,
  },
  props: {
    file: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      fileAttachment: new FileAttachment(this.file),
    };
  },
};
</script>

<style lang="scss" scoped>
.file {
  padding: 0 16px;
  margin-top: 6px;
  cursor: pointer;
  display: flex;

  svg {
    margin-right: 8px;
    width: 32px;
    height: 32px;
  }

  .info {
    display: flex;
    flex-direction: column;

    .file-size {
      font: $font-rn-200;
      color: $clr-text-trietary;
    }
  }
}
</style>
