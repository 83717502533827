<template>
  <div class="messenger">
    <People
      class="people"
      :class="{ hidden: !inMenu }"
    />
    <ChatBody
      class="chat-body"
      :class="{ hidden: inMenu }"
      :chat-from-router="chatFromRouter"
      :visible="!inMenu"
    />
  </div>
</template>

<script>
import ChatBody from './ChatBody/Index.vue';
import People from './Chats/Index.vue';

import chatFactory from '@/models/chatFactory';

export default {
  name: 'Messenger',
  components: {
    People,
    ChatBody,
  },
  props: {
    chatId: {
      type: [String, Number],
      default: undefined,
    },
    chatStringified: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      inMenu: true,
      chatFromRouter: undefined,
    };
  },
  watch: {
    $route() {
      if (this.$route.params.chatId) {
        this.inMenu = false;
        this.$emitter.emit('hideNav');
      } else {
        this.inMenu = true;
      }
    },
    chatStringified: {
      handler() {
        if (this.chatStringified) {
          this.chatFromRouter = chatFactory(JSON.parse(this.chatStringified));
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.chatId) {
      if (!this.$route.params.chatId) {
        this.$router.replace({ name: 'Messenger', params: { chatId: this.chatId } });
      }

      this.inMenu = false;
      this.$nextTick(() => {
        this.$emitter.emit('hideNav');
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.messenger {
  border-left: 1px solid $clr-border-dark;
  border-right: 1px solid $clr-border-dark;
  padding-top: 64px !important;
  max-width: calc(1024px + 64px);
  margin: auto;
  display: flex;
  box-shadow: $shadow-3;

  .people {
    width: 30%;
  }

  .chat-body {
    width: 70%;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .messenger {
    padding-top: calc(64px + env(safe-area-inset-top)) !important;
    position: relative;
    display: block;
    height: 100vh;
    box-sizing: border-box;
    max-width: 100%;
    overflow-x: hidden;

    .people, .chat-body {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transition: transform .7s;
      margin: 0;
    }

    .people.hidden {
      transform: translateX(-100%);
    }

    .chat-body.hidden {
      transform: translateX(100%);
    }
  }
}
</style>
