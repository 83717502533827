<template>
  <Item
    :selected="selected"
    :avatar="chat.avatar"
    :name="chat.name"
    :verified="chat.verified"
    :text="lastMessage"
    :has-attachment="hasAttachment"
    :date="new Date(chat.lastMessage && chat.lastMessage.created_at)"
    :counter="chat.unreadCount"
    :hide-info="!chat.lastMessage || chat.type === 'jinny'"
    :read="chat.lastMessage && chat.lastMessage.read"
    @click.native="select"
  />
</template>

<script>
import linkToChatMixin from '@/mixins/linkToChatMixin';

import callStatusTextMixin from '../callStatusTextMixin';

import Item from './Item.vue';

import Chat from '@/models/Chat';

export default {
  components: {
    Item,
  },
  mixins: [callStatusTextMixin, linkToChatMixin],
  props: {
    chat: {
      type: Chat,
      required: true,
    },
  },
  computed: {
    selected() {
      return this.$route.params.chatId === this.getLongId(this.chat);
    },
    lastMessage() {
      if (this.chat.lastMessage) {
        if (this.chat.lastMessage.round) {
          return this.$t('post');
        }
        if (this.chat.lastMessage.gallery) {
          if (this.chat.lastMessage.gallery.length === 1) {
            if (this.chat.lastMessage.gallery[0].image) {
              return this.$t('messenger.image');
            }
            return this.$t('messenger.video');
          }
          return this.$t('messenger.gallery');
        }
        if (this.chat.lastMessage.audio) {
          return this.$t('messenger.audio');
        }
        if (this.chat.lastMessage.file) {
          return this.$t('messenger.file');
        }
        if (this.chat.lastMessage.model) {
          return this.$t('messenger.model');
        }
        if (this.chat.lastMessage.call) {
          const { call } = this.chat.lastMessage;

          return this.callStatusText(call);
        }
        if (this.chat.lastMessage.geo) {
          return this.$t('messenger.geo');
        }

        return this.chat.lastMessage.text;
      }
      return '';
    },
    hasAttachment() {
      if (this.chat.lastMessage === undefined) {
        return false;
      }

      const attachments = ['round', 'audio', 'file', 'model', 'call', 'geo', 'gallery'];
      let hasAttachment = false;

      attachments.forEach((attachment) => {
        if (this.chat.lastMessage[attachment]) {
          hasAttachment = true;
        }
      });

      return hasAttachment;
    },
  },
  methods: {
    select() {
      if (!this.selected) {
        this.$router.replace(this.getLinkToChat(this.chat));
        this.$store.commit('selectMessage', this.chat.firstUnreadId);

        this.$emitter.emit('enterChat');
      }
    },
  },
};
</script>
