<template>
  <div
    v-if="message && message.pinned"
    class="pinned-message"
  >
    <PinFilledIcon class="pin-icon" />
    <div class="message">
      <div class="author">
        <router-link
          v-if="message.author && message.author.link"
          :to="message.author.link"
          class="name"
        >
          {{ message.author.name }}
        </router-link>
        <span class="date">
          {{ formatDateTime(message.createdAt) }}
        </span>
      </div>
      <router-link
        class="text"
        :to="message.link"
      >
        {{ message.text }}
      </router-link>
    </div>
    <a
      v-if="message.file"
      class="attachment"
      :href="message.file.url"
      download
    >
      <FileIcon />
    </a>
    <CrossIcon
      v-if="canPin"
      class="cross-icon"
      @click.native="unpin"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import formatDateTimeMixin from '@/mixins/formatDateTimeMixin';

import CrossIcon from '@/components/icons/Cross.vue';
import FileIcon from '@/components/icons/File.vue';
import PinFilledIcon from '@/components/icons/PinFilled.vue';

import Chat from '@/models/Chat';
import messageFromBackend from '@/models/dto/messageFromBackend';

export default {
  components: {
    PinFilledIcon,
    FileIcon,
    CrossIcon,
  },
  mixins: [formatDateTimeMixin],
  props: {
    chat: {
      type: Chat,
      required: true,
    },
  },
  data() {
    return {
      message: null,
    };
  },
  computed: {
    chatId() {
      return String(this.$route.params.chatId);
    },
    msgSocket() {
      return this.$store.state.api.msgSocket;
    },
    canPin() {
      return this.chat.admin || this.chat.type === 'dm';
    },
  },
  watch: {
    chatId: {
      async handler() {
        this.hidden = false;
        this.message = null;
        const response = await this.getPinnedMessage(this.chatId);
        this.message = messageFromBackend(response);
      },
      immediate: true,
    },
    msgSocket: {
      handler() {
        if (this.msgSocket) {
          this.msgSocket.addEventListener('message', this.handleResponse);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['getPinnedMessage', 'pinMessage']),
    handleResponse(response) {
      if (!response.data) return;

      const handlers = {
        message_update: this.messageUpdate,
        message_delete: this.messageDelete,
      };

      const action = JSON.parse(response.data);
      const actionName = Object.keys(action)[0];

      setTimeout(() => {
        handlers[actionName]?.(action[actionName]); // eslint-disable-line
      });
    },
    messageUpdate(msg) {
      const newMessage = messageFromBackend(msg);
      if (newMessage.pinned && newMessage.chatId === this.chatId) {
        this.message = newMessage;
      } else if (newMessage.id === this.message.id) {
        this.message = newMessage;
      }
    },
    messageDelete(msg) {
      if (msg.message_id === this.message.id) {
        this.message = null;
      }
    },
    unpin() {
      this.pinMessage({
        chatId: this.chatId,
        id: this.message.id,
        pinned: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pinned-message {
  position: relative;
  width: 100%;
  z-index: 180;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  box-sizing: border-box;
  background: white;
  border-top: 1px solid $clr-border-light;
  border-bottom: 1px solid $clr-border-light;

  .pin-icon, .cross-icon {
    width: 20px;
    min-width: 20px;
    padding: 14px;
  }

  .pin-icon {
    margin-right: 12px;
  }

  .cross-icon {
    margin-left: 12px;
    cursor: pointer;
  }

  .message {
    flex-grow: 1;
    overflow: hidden;

    .author {
      font: $font-sbw-300;

      .name {
        margin-right: 6px;
      }

      .date {
        font: $font-rn-300;
        color: $clr-text-secondary;
      }
    }

    .text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font: $font-rn-300;
    }
  }

  .attachment {
    padding: 0 16px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .pinned-message {
    .pin-icon {
      margin-right: 16px;
    }

    .attachment {
      display: none;
    }
  }
}
</style>
