<template>
  <div class="subs">
    <MiniEntity
      v-for="sub in room.subs"
      :key="sub.id"
      :entity="sub"
    />
  </div>
</template>

<script>
import MiniEntity from '@/components/MiniEntity.vue';

export default {
  components: {
    MiniEntity,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.subs {
  max-height: 400px;
  overflow-y: scroll;
}
</style>
