<template>
  <div
    v-if="replied"
    class="quote"
  >
    <router-link
      class="author"
      :to="{ name: 'Profile', params: { id: replied.author.id } }"
    >
      {{ replied.author.name }}
    </router-link>
    <div
      class="quote-content"
      @click.stop="goToMessage"
    >
      <img
        v-if="replied.image"
        :src="replied.image.url"
      >
      <span class="quote-text">
        {{ replied.text }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    replied: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    goToMessage() {
      this.$router.push(this.replied.link);
    },
  },
};
</script>

<style lang="scss" scoped>
.quote {
  padding: 0 8px;
  margin: 8px 16px;
  border-left: 2px solid var(--clr-brand);

  .author {
    color: var(--clr-brand);
  }

  .quote-content {
    cursor: pointer;
    display: flex;

    img {
      min-width: 32px;
      max-width: 32px;
      height: 32px;
      object-fit: cover;
      margin-right: 4px;
    }

    .quote-text {
      color: $clr-gray;
      font: $font-rn-200;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
</style>
