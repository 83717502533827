<template>
  <Modal
    ref="modal"
  >
    <div class="dialog">
      <h1>
        {{ $t('messenger.join_channel') }}
        {{ chat.username }}
      </h1>
      <div>
        {{ $t('messenger.if_you_like_channel') }}
        "{{ chat.name }}"
      </div>
      <button
        class="btn primary"
        @click="join"
      >
        {{ $t('messenger.join') }}
      </button>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';

import Modal from '@/components/Modal.vue';

import Chat from '@/models/Chat';

export default {
  emits: ['joined'],
  components: {
    Modal,
  },
  props: {
    chat: {
      type: Chat,
      required: true,
    },
  },
  methods: {
    ...mapActions(['joinChannel']),
    open() {
      this.$refs.modal.open();
    },
    join() {
      this.joinChannel({ id: this.chat.id });
      this.$emit('joined');
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  margin: -32px 0;

  > * {
    margin: 32px 0;
  }

  button {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
