import Group from '../Group';

// handles https://arround-msg.gora.studio/doc/#api-Group-get
export default function groupFromBackend(data) {
  const group = new Group();

  group.id = data.id;
  group.admin = data.admin;
  group.muted = data.muted;
  group.name = data.name;
  group.username = data.username;
  group.avatar = data.avatar;
  group.createdAt = new Date(data.created_at);
  group.description = data.description;
  group.subscriber = data.subscriber;
  group.subscriberCount = data.subscriber_count;

  return group;
}
