<template>
  <div class="info">
    <div class="name">
      <input
        ref="title"
        v-model="newRoom.name"
        type="text"
        class="classic-input"
        :disabled="!room.admin"
      >
      <EditIcon
        v-if="room.admin"
        class="btn"
        @click.native="focusTitle"
      />
    </div>
    <input
      v-model="newRoom.username"
      spellcheck="false"
      type="text"
      class="classic-input"
      :disabled="!room.admin"
    >
    <textarea
      v-model="newRoom.description"
      :disabled="!room.admin"
    />

    <button
      v-if="room.admin"
      class="btn primary"
      :disabled="!edited"
      @click="submit"
    >
      {{ $t('save') }}
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import EditIcon from '@/components/icons/Edit.vue';

export default {
  emits: ['updated'],
  components: {
    EditIcon,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newRoom: {
        name: this.room.name,
        username: this.room.username,
        description: this.room.description,
      },
    };
  },
  computed: {
    diff() {
      const edited = { id: this.room.id };
      Object.keys(this.newRoom).forEach((key) => {
        if (this.newRoom[key] !== this.room[key]) {
          edited[key] = this.newRoom[key];
        }
      });
      return edited;
    },
    edited() {
      return Object.keys(this.diff).length > 1;
    },
  },
  methods: {
    ...mapActions(['updateGroup', 'updateChannel']),
    focusTitle() {
      this.$refs.title.focus();
    },
    async submit() {
      if (this.room.type === 'group') {
        this.updateGroup(this.diff);
      } else {
        this.updateChannel(this.diff);
      }
      this.$emit('updated');
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  > * {
    width: 100%;
    margin-bottom: 12px;
  }

  .name {
    position: relative;
    width: calc(100% - 32px);

    input {
      width: 100%;
      background: none;
      padding: 0 32px 0 0;
      font: $font-h-200;
    }

    svg {
      position: absolute;
      right: 0;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .info {
    .name {
      width: calc(100% - 48px);
    }
  }
}
</style>
