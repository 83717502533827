<template>
  <div
    v-if="model"
    class="model"
  >
    <div class="title">
      {{ $t('messenger.threed_model') }}
    </div>
    <img :src="model.modifications[0].url_icon">
    <div class="model-name">
      {{ model.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.model {
  .title, .model-name {
    padding: 8px 16px;
    font: $font-rw-300;
  }

  img {
    width: 200px;
  }
}
</style>
