<template>
  <viewer
    v-if="image"
    :images="[image.url]"
    :options="options"
    @click.native.stop
  >
    <img :src="image.url">
  </viewer>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      options: {
        button: false,
        navbar: false,
        title: false,
        toolbar: false,
        movable: false,
        zoomable: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
img {
  display: block;
  min-width: 300px;
  width: 100%;
  margin-top: 16px;
  cursor: pointer;
}

@media screen and (max-width: $mobile-breakpoint) {
  img {
    max-width: 100%;
  }
}
</style>
