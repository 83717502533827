<template>
  <EmbeddedVideo
    class="video"
    :video="video"
  />
</template>

<script>
import EmbeddedVideo from '@/components/EmbeddedVideo.vue';

export default {
  components: {
    EmbeddedVideo,
  },
  props: {
    video: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.video {
  max-width: 100%;
  margin-top: 16px;
}

@media screen and (max-width: $mobile-breakpoint) {
  .video {
    max-width: 100%;
  }
}
</style>
