<template>
  <div
    class="button"
    @click="mute"
  >
    <div class="supel">
      <MuteIcon v-if="chat.muted" />
      <SpeakerIcon v-else />
    </div>
    {{
      chat.muted
        ? $t("messenger.no_sound")
        : $t("messenger.with_sound")
    }}
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import MuteIcon from '@/components/icons/Mute.vue';
import SpeakerIcon from '@/components/icons/Speaker.vue';

import './button.scss';

export default {
  components: {
    MuteIcon,
    SpeakerIcon,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(['muteChat']),
    mute() {
      this.chat.muted = !this.chat.muted;
      this.muteChat(this.chat.muted);
    },
  },
};
</script>
