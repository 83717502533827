<template>
  <Modal
    ref="modal"
  >
    <h1>
      {{ $t("messenger.forward") }}
    </h1>
    <div class="chats">
      <Chat
        v-for="chat in forwardableChats"
        :key="chat.id"
        v-model="selectedChats"
        :value="chat.id"
        :chat="chat"
      />
    </div>
    <div class="submit">
      <button
        class="btn primary"
        :disabled="selectedChats.length === 0"
        @click="send"
      >
        {{ $t('confirm') }}
      </button>
      <SpinnerIcon
        v-if="loading"
        class="loading"
      />
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import SpinnerIcon from '@/components/icons/Spinner.vue';

import Modal from '@/components/Modal.vue';

import Chat from './Chat.vue';

export default {
  name: 'ForwardModal',
  components: {
    Modal,
    Chat,
    SpinnerIcon,
  },
  data() {
    return {
      selectedChats: [],
      loading: false,
      message: undefined,
    };
  },
  computed: {
    ...mapGetters(['allChats']),
    forwardableChats() {
      return this.allChats.filter((chat) => chat.type !== 'jinny' && !chat.archived);
    },
  },
  methods: {
    ...mapActions(['sendMessage']),
    open(msg) {
      this.selectedChats = [];
      this.loading = false;
      this.message = msg;
      this.$refs.modal.open();
    },
    async send() {
      this.loading = true;
      const messages = this.selectedChats.map((chat) => ({
        chat_id: chat,
        fwd_chat_id: this.message.chat_id,
        fwd_message_id: this.message.id,
        text: '',
      }));

      const sendMessages = messages.map(async (message) => this.sendMessage(message));
      await Promise.all(sendMessages);
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.chats {
  max-height: 400px;
  margin: 32px 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.submit {
  position: relative;
  width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;

  .loading {
    position: absolute;
    right: -32px;
  }
}
</style>
