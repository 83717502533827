<template>
  <div
    id="chat-body"
    class="chat-body"
  >
    <ChatHeader
      v-if="visible"
      :chat="chat"
      @unarchived="chat.archived = false"
    />
    <PinnedMessage
      v-if="chat"
      :chat="chat"
    />
    <div class="bg-pattern" />
    <div
      v-if="messages.length"
      ref="messages"
      class="messages"
      :class="{
        'no-scroll': noScroll
      }"
      @scroll="onScroll"
    >
      <div
        v-for="(mges, date) in messagesByDate"
        :key="date"
      >
        <DateBubble
          :created-at="date"
        />
        <Message
          v-for="msg in mges"
          :key="msg.id"
          :ref="isSelected(msg)"
          :msg="msg"
          :first-unread="chatId !== 'jinny' && msg.id === firstUnread"
          :selected-chat="chat"
          :messages-rect="messagesRect"
          @delete="openDeleteModal(msg)"
          @forward="openForwardModal(msg)"
          @contextMenuOpen="disableScrolling"
          @contextMenuClosed="enableScrolling"
        >
          <div class="scroll-anchor" />
        </Message>
      </div>
    </div>
    <div
      v-else-if="chatId"
      class="no-messages"
    >
      <div v-if="loadingBack || loadingFwd">
        <SpinnerIcon />
      </div>
      <div v-else>
        {{ $t("messenger.no_messages") }}
      </div>
    </div>
    <div
      v-else
      class="no-messages"
    >
      {{ $t("messenger.not_selected") }}
    </div>

    <MessageInput
      v-show="canMessage"
      :subs="chat && chat.subs"
      @jinnyMessage="jinnyMessage"
      @sentMessage="addLocalMessage"
    />

    <DeleteMessageModal
      ref="delete"
      :delete-target="deleteTarget"
    />

    <ForwardModal
      ref="forward"
    />

    <JoinModal
      v-if="chat"
      ref="join"
      :chat="chat"
      @joined="chat.subscriber = true"
    />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

import formatDateTime from '@/mixins/formatDateTimeMixin';
import jinnyChat from '@/store/jinnyChat';

import SpinnerIcon from '@/components/icons/Spinner.vue';

import chatNumFromId from '../chatNumFromId';
import chatTypeFromId from '../chatTypeFromId';

import ChatHeader from './ChatHeader/Index.vue';
import DeleteMessageModal from './DeleteMessageModal.vue';
import ForwardModal from './ForwardModal/Index.vue';
import JoinModal from './JoinModal.vue';
import DateBubble from './Message/Date.vue';
import Message from './Message/Index.vue';
import MessageInput from './MessageInput/Index.vue';
import PinnedMessage from './PinnedMessage.vue';

import Chat from '@/models/Chat';
import channelFromBackend from '@/models/dto/channelFromBackend';
import chatFromBackend from '@/models/dto/chatFromBackend';
import groupFromBackend from '@/models/dto/groupFromBackend';

export default {
  name: 'ChatBody',
  components: {
    SpinnerIcon,
    ChatHeader,
    Message,
    DeleteMessageModal,
    ForwardModal,
    JoinModal,
    MessageInput,
    PinnedMessage,
    DateBubble,
  },
  mixins: [formatDateTime],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    chatFromRouter: {
      type: Chat,
      default: () => {},
    },
  },
  data() {
    return {
      messages: [],
      loadingBack: false,
      loadingFwd: false,
      atBottom: true,
      firstUnread: undefined,
      mobile: false,
      deleteTarget: undefined,
      chat: undefined,
      messagesRect: undefined,
      noScroll: false,
    };
  },
  computed: {
    ...mapGetters([
      'msgSocket',
      'allChats',
      'jinnyHistory',
      'profile',
    ]),
    ...mapState([
      'selectedMessageId',
    ]),
    chatId() {
      return this.$route.params.chatId && String(this.$route.params.chatId);
    },
    searchResultId() {
      return this.$route.query.mid;
    },
    canMessage() {
      if (this.chat) {
        return this.chat.type !== 'channel' || this.chat.admin;
      }
      if (this.chatId) {
        return !this.chatId.includes('channel');
      }
      return false;
    },
    focusId() {
      return this.searchResultId || this.selectedMessageId;
    },
    messagesByDate() {
      const finalMessages = {};

      this.messages.forEach((message) => {
        const dateString = this.formatDateShort(new Date(message.created_at));

        if (!finalMessages[dateString]) finalMessages[dateString] = [];

        finalMessages[dateString].unshift(message);
      });

      const ordered = {};
      Object.keys(finalMessages)
        .forEach((key) => {
          ordered[key] = finalMessages[key];
        });

      return ordered;
    },
  },
  watch: {
    chatId: {
      async handler(newLongId) {
        this.selectMessage(null);
        this.messages = [];
        this.firstUnread = undefined;
        this.chat = undefined;

        if (newLongId === 'jinny') {
          this.chat = chatFromBackend(jinnyChat);
          this.messages = this.jinnyHistory;
          return;
        }

        if (newLongId) {
          const baseChat = chatFromBackend(await this.getChat({ longId: newLongId }));
          if (chatTypeFromId(newLongId) === 'channel') {
            this.chat = channelFromBackend(await this.getChannel({
              id: chatNumFromId(newLongId),
            }));
            this.chat.update(baseChat);
          } else if (chatTypeFromId(newLongId) === 'group') {
            this.chat = groupFromBackend(await this.getGroup({
              id: chatNumFromId(newLongId),
            }));
            this.chat.update(baseChat);
          } else {
            this.chat = baseChat;
          }
        }

        if (!this.chat) {
          this.chat = this.chatFromRouter;
        }

        if (newLongId) {
          this.loadingBack = true;
          if (!this.searchResultId) {
            this.messages = await this.fetchMessages({});
          }
          this.loadingBack = false;
          this.firstUnread = this.chat?.firstUnreadId;
          setTimeout(() => {
            if (this.$refs.selected && this.$refs.selected.length !== 0) {
              this.$refs.selected[0].$el.scrollIntoView();
            } else if (this.$refs.messages) {
              this.$refs.messages.scrollTop = this.$refs.messages.scrollheight;
            }

            if (this.$refs.messages) {
              this.messagesRect = this.$refs.messages.getBoundingClientRect();
            }
          });
        }
      },
      immediate: true,
    },
    focusId: {
      async handler(newVal) {
        const scrollToSelected = () => {
          if (this.$refs.selected?.[0]) {
            this.$refs.selected[0].$el.querySelector('.scroll-anchor').scrollIntoView({ behavior: 'smooth' });
          }
        };

        if (newVal) {
          // this.loadingFwd = true;
          this.messages = await this.fetchMessages({ messageId: newVal, radiusLimit: 40 });
          // this.loadingFwd = false;

          setTimeout(() => {
            scrollToSelected();
          });
          // Arbitrary delay for content to hopefully load and not fuck up the scroll position
          const goToResultInterval = setInterval(() => {
            scrollToSelected();
          }, 1000);
          setTimeout(() => clearInterval(goToResultInterval), 3000);
        }
      },
      immediate: true,
    },
    chat: {
      async handler(newVal, oldVal) {
        if (newVal !== undefined && newVal?.id !== oldVal?.id) {
          if (this.chat.type === 'group') {
            this.$set(this.chat, 'subs', await this.getGroupSubs({ id: this.chat.id }));
          } else if (this.chat.type === 'channel') {
            if (this.chat.admin) {
              this.$set(this.chat, 'subs', await this.getChannelSubs({ id: this.chat.id }));
            }
            if (!this.chat.subscriber) {
              this.$nextTick(() => this.$nextTick(this.$refs.join.open));
            }
          }
        }
      },
      immediate: true,
    },
    msgSocket: {
      handler() {
        if (this.msgSocket) {
          this.msgSocket.addEventListener('message', this.handleResponse);
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.mobile = window.innerWidth <= this.$scss.mobileBreakpoint;
  },
  methods: {
    ...mapActions([
      'fetchMessages',
      'readMessages',
      'getGroupSubs',
      'getChannelSubs',
      'getChat',
      'getChannel',
      'getGroup',
    ]),
    ...mapMutations(['selectMessage']),
    handleResponse(response) {
      if (!response.data) return;

      const handlers = {
        message_create: this.messageCreate,
        message_delete: this.removeMessage,
        chat_update: this.updateChat,
      };

      const action = JSON.parse(response.data);
      const actionName = Object.keys(action)[0];

      setTimeout(() => {
        handlers[actionName]?.(action[actionName]); // eslint-disable-line
      });
    },
    updateChat(chat) {
      this.chat.update(chatFromBackend(chat));
    },
    messageCreate(msg) {
      const scrollToBottom = () => {
        this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
      };

      const lastMessageUnconfirmed = () => this.messages[0].unconfirmed;

      if (msg.chat_id === this.chatId) {
        if (lastMessageUnconfirmed()) {
          this.$set(this.messages, 0, msg);
        } else {
          this.messages = [msg, ...this.messages];
        }

        if (this.atBottom && (this.visible || !this.mobile)) {
          setTimeout(() => {
            this.readMessages({
              message: msg,
              firstUnreadId: this.chat.firstUnreadId,
            });
            scrollToBottom();
          });
        }
      }
    },
    async fetchMore(dir) {
      const direction = dir ?? 'back';
      if (direction === 'back' && !this.loadingBack) {
        this.loadingBack = true;
        const lastMessage = this.messages[this.messages.length - 1].id;
        const recieved = await this.fetchMessages({ messageId: lastMessage, backLimit: 40 });
        if (recieved.length !== 0) {
          this.messages = this.messages.concat(recieved);
          this.loadingBack = false;
        }
      }
      if (direction === 'fwd' && !this.loadingFwd) {
        this.loadingFwd = true;
        const firstMessage = this.messages[0].id;
        const recieved = await this.fetchMessages({ messageId: firstMessage, fwdLimit: 40 });
        if (recieved.length !== 0) {
          this.messages = recieved.concat(this.messages);
          this.loadingFwd = false;
        }
      }
    },
    onScroll({
      target: {
        scrollTop, clientHeight, scrollHeight,
      },
    }) {
      // Scrolling up
      if (Math.abs(scrollTop - clientHeight) >= scrollHeight - clientHeight) {
        this.fetchMore();
      }
      // Scrolling down
      if (scrollTop > -5) {
        this.fetchMore('fwd');
      }

      if (scrollTop > -5) {
        this.atBottom = true;
      } else {
        this.atBottom = false;
      }
    },
    openDeleteModal(msg) {
      this.$refs.delete.open();
      this.deleteTarget = msg;
      this.deleteTarget.own = msg.author_id === this.profile.id;
    },
    openForwardModal(msg) {
      this.$refs.forward.open(msg);
    },
    removeMessage(removed) {
      this.messages = this.messages.filter(
        (msg) => msg.id !== removed.message_id,
      );
    },
    jinnyMessage(msg) {
      this.messages = [msg, ...this.messages];
    },
    addLocalMessage(msg) {
      this.messageCreate({
        ...msg,
        id: Date.now(),
        author_id: this.profile.id,
        author: {
          id: this.profile.id,
          type: 'user',
        },
        created_at: new Date().toISOString(),
        unconfirmed: true,
      });
    },
    isSelected(msg) {
      if (this.chat) {
        const idsOfInterest = [
          this.chat.first_unread_id,
          this.selectedMessageId,
          this.searchResultId,
        ];
        if (idsOfInterest.includes(msg.id)) {
          return 'selected';
        }
        return undefined;
      }
      return undefined;
    },
    getDate(index) {
      return this.messages[index]?.created_at;
    },
    disableScrolling() {
      this.noScroll = true;
    },
    enableScrolling() {
      this.noScroll = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-body {
  position: relative;
  // All but header height
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  z-index: 10;

  .bg-pattern {
    z-index: -10;
    position: absolute;
    background: url("~@/assets/pttrn.svg");
    background-color: $clr-mint;
    opacity: 0.1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .no-messages {
    position: relative;
    flex-grow: 1;
    color: $clr-text-secondary;
    font: $font-sbw-400;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .messages {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;

    .scroll-anchor {
      position: absolute;
      top: -30vh;
    }

    &.no-scroll {
      overflow-y: hidden;
      padding-right: 32px;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;

    button {
      cursor: pointer;
      padding: 8px 12px;
      margin-top: 8px;
      border: none;
      background: none;
      color: $clr-error;
      font: $font-rw-500;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .chat-body {
    .messages {
      padding: 0 8px 34px 8px;

      &.no-scroll {
        padding-right: 8px;
      }
    }
  }

  .modal-content {
    button {
      font: $font-rw-600 !important;
    }
  }
}
</style>
