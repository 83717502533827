<template>
  <div
    v-if="call"
    class="call"
  >
    {{ callText }}
  </div>
</template>

<script>
import callStatusTextMixin from '../../../callStatusTextMixin';

export default {
  mixins: [callStatusTextMixin],
  props: {
    call: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    callText() {
      return this.callStatusText(this.call);
    },
  },
};
</script>

<style lang="scss" scoped>
.call {
  padding: 0 16px;
  font: $font-rn-300;
  color: $clr-text-secondary;
}
</style>
