<template>
  <div class="attachments">
    <div
      v-if="message.forwarded"
      class="forward"
    >
      {{ $t('messenger.forwarded_from') }} {{ message.forwarded.author.name }}
    </div>
    <!-- <ImageAttach :image="message.image" /> -->
    <ModelAttach :model="message.model" />
    <RoundAttach
      v-if="message.round"
      class="round"
      :round-instance="message.round"
    />
    <!-- <VideoAttach :video="message.video" /> -->
    <GalleryAttach :gallery="message.gallery" />
    <VoiceAttach :audio="message.audio" />
    <FileAttach :file="message.file" />
    <GeoAttach :geo="message.geo" />
    <CallAttach :call="message.call" />
    <ReplyAttach :replied="message.replied" />
  </div>
</template>

<script>
// import ImageAttach from './Image.vue';
// import VideoAttach from './Video.vue';
import RoundAttach from '@/components/Post/Index.vue';

import CallAttach from './Call.vue';
import FileAttach from './File.vue';
import GalleryAttach from './Gallery.vue';
import GeoAttach from './Geo.vue';
import ModelAttach from './Model.vue';
import ReplyAttach from './Reply.vue';
import VoiceAttach from './Voice.vue';

import Message from '@/models/Message';

export default {
  name: 'Attachments',
  components: {
    // ImageAttach,
    ModelAttach,
    // VideoAttach,
    GalleryAttach,
    VoiceAttach,
    RoundAttach,
    FileAttach,
    GeoAttach,
    CallAttach,
    ReplyAttach,
  },
  props: {
    message: {
      type: Message,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.attachments {
  padding-bottom: 8px;
}

.round {
  max-width: 300px;
  margin-top: 16px;
}

.forward {
  padding: 0 16px;
  color: $clr-text-secondary;
  font: $font-rw-200;
}
</style>
