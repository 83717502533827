<template>
  <div>
    <Chat
      v-for="chat in list"
      :key="chat.id"
      :ref="($route.params.chatId === chat.id) ? 'selected' : undefined"
      :chat="chat"
    />
  </div>
</template>

<script>
import Chat from './Chat.vue';

export default {
  components: {
    Chat,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.selected?.[0]) {
        this.$refs.selected[0].$el.scrollIntoView();
      }
    });
  },
};
</script>
