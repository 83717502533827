<template>
  <Item
    :selected="selected"
    :avatar="searchResult.avatar"
    :name="searchResult.name"
    :verified="searchResult.verified"
    :text="searchResult.text"
    :has-attachment="searchResult.hasAttachment"
    :date="new Date(searchResult.date)"
    :read="searchResult.read"
    @click.native="select"
  />
</template>

<script>
import linkToChatMixin from '@/mixins/linkToChatMixin';

import Item from './Item.vue';

export default {
  components: {
    Item,
  },
  mixins: [linkToChatMixin],
  props: {
    searchResult: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: false,
    };
  },
  mounted() {
    this.$emitter.on('enterChat', () => { this.selected = false; });
  },
  methods: {
    select() {
      this.$router.replace({
        name: 'Messenger',
        params: { chatId: this.searchResult.longId },
        query: { mid: this.searchResult.messageId },
      });

      this.$emitter.emit('enterChat');
      this.selected = true;
    },
  },
};
</script>
