<template>
  <div
    v-if="canAttach"
    class="attach"
  >
    <label
      for="file-attachment"
    >
      <PaperclipIcon />
    </label>

    <input
      id="file-attachment"
      data-qa="msg-input-file-input"
      type="file"
      multiple
      :accept="acceptedTypes"
      @input="handleFileSelect"
    >
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import PaperclipIcon from '@/components/icons/Paperclip.vue';

import { imageTypes, videoTypes, documentTypes } from './acceptedFiletypes';

import attachmentFactory from '@/models/attachmentFactory';

export default {
  emits: ['attachmentUpdated', 'removeAttachment'],
  components: {
    PaperclipIcon,
  },
  props: {
    canAttach: {
      type: Boolean,
    },
  },
  computed: {
    acceptedTypes() {
      const allTypes = [...imageTypes, ...videoTypes, ...documentTypes];
      return allTypes.toString();
    },
  },
  mounted() {
    window.addEventListener('paste', this.handlePaste);
    window.addEventListener('dragover', (event) => {
      event.preventDefault();
    });
    window.addEventListener('drop', this.handleDrop);
  },
  beforeDestroy() {
    window.removeEventListener('paste', this.handlePaste);
    window.removeEventListener('dragover', (event) => {
      event.preventDefault();
    });
    window.removeEventListener('drop', this.handleDrop);
  },
  methods: {
    ...mapActions(['uploadFile']),
    async handleAttach(fileList) {
      const files = Array.from(fileList);
      files.length = Math.min(files.length, 10);

      files.forEach(async (file, i) => {
        const attachment = await attachmentFactory(file, i);
        console.log(`Type: ${attachment.file.type}`);
        this.$emit('attachmentUpdated', attachment);

        const result = await this.uploadFile({
          file,
          vm: this,
          onProgress: (event) => {
            attachment.progress = event;
            this.$emit('attachmentUpdated', attachment);
          },
        });

        this.$emit('attachmentUpdated', attachment.setId(result.id));
      });
    },
    async handleFileSelect(event) {
      this.$emit('removeAttachment');
      this.handleAttach(event.target.files);
    },
    filesFromEvent(eventItems) {
      const items = Array.from(eventItems);
      const acceptedItems = items.filter((item) => this.acceptedTypes.includes(item.type));
      const files = acceptedItems.map((item) => item.getAsFile());
      return files;
    },
    handleDrop(event) {
      event.preventDefault();

      if (event.dataTransfer.items) {
        const files = this.filesFromEvent(event.dataTransfer.items);
        this.$emit('removeAttachment');
        this.handleAttach(files);
      }
    },
    handlePaste(event) {
      if (event.clipboardData.items) {
        const files = this.filesFromEvent(event.clipboardData.items);
        if (files[0] !== null) {
          this.$emit('removeAttachment');
          this.handleAttach(files);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="file"] {
  display: none;
}

.attach {
  position: relative;
  margin-bottom: 22px;
  display: flex;

  label {
    position: relative;
    display: block;
    cursor: pointer;
    margin-right: 8px;

    svg {
      min-width: 22px;
      min-height: 22px;
    }
  }
}
</style>
