var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.geo)?_c('div',{staticClass:"geo"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.geo,"zoom":7,"options":{
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
    }}},[_c('GmapMarker',{attrs:{"position":_vm.geo}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }