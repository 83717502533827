<template>
  <div>
    <div
      v-if="gallery.length > 1"
    >
      <PhotoCollageWrapper
        class="collage"
        v-bind="collage"
        @itemClick="handleGalleryClick"
      />
      <FsLightbox
        :toggler="lightbox.toggler"
        :slide="lightbox.slide"
        :sources="gallery.map((item) => item.url)"
      />
    </div>
    <div v-else-if="gallery.length === 1">
      <VideoAttach
        v-if="(gallery[0] instanceof VideoFile)"
        :video="gallery[0]"
      />
      <ImageAttach
        v-else
        :image="gallery[0]"
      />
    </div>
  </div>
</template>

<script>
import FsLightbox from 'fslightbox-vue';
import { PhotoCollageWrapper } from 'vue-photo-collage';

import ImageAttach from './Image.vue';
import VideoAttach from './Video.vue';

import VideoFile from '@/models/VideoFile';

export default {
  components: {
    PhotoCollageWrapper,
    FsLightbox,
    ImageAttach,
    VideoAttach,
  },
  props: {
    gallery: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    let layout = [1, 1];
    if (this.gallery.length >= 4) {
      layout = [2, 2];
    } else if (this.gallery.length >= 3) {
      layout = [2, 1];
    }

    return {
      collage: {
        gapSize: '2px',
        width: '100%',
        height: ['150px', '150px'],
        layout,
        photos: this.gallery.map((item) => ({
          source: item.preview ?? item.url,
        })),
        showNumOfRemainingPhotos: true,
      },
      lightbox: {
        toggler: false,
        slide: 0,
      },
    };
  },
  beforeMount() {
    this.VideoFile = VideoFile;
  },
  methods: {
    handleGalleryClick(data, column) {
      this.lightbox.toggler = !this.lightbox.toggler;
      this.lightbox.slide = data.id + 1;
    },
  },
};
</script>

<style lang="scss">
  .fslightbox-nav {
    top: $top-nav-height;
  }
</style>

<style lang="scss" scoped>
.collage {
  min-width: 300px;
  width: 100%;
  margin-top: 16px;
}

@media screen and (max-width: $mobile-breakpoint) {
  .collage {
    max-width: 100%;
    cursor: pointer;
  }
}
</style>
