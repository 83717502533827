<template>
  <div>
    <SearchResult
      v-for="(searchResult, index) in list"
      :key="index"
      :search-result="searchResult"
    />
  </div>
</template>

<script>
import SearchResult from './SearchResult.vue';

export default {
  components: {
    SearchResult,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
