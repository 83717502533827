export default {
  methods: {
    callStatusText(call) {
      if (call.cancelled_by) {
        if (call.cancelled_by === this.$store.getters.profile.id) {
          return this.$t('messenger.call_out_missed');
        }
        return this.$t('messenger.call_in_missed');
      }
      if (call.caller_id === this.$store.getters.profile.id) {
        return this.$t('messenger.call_out');
      }
      return this.$t('messenger.call_in');
    },
  },
};
