export const imageTypes = [
  'image/png',
  'image/jpeg',
  'image/gif',
];

export const videoTypes = [
  'video/mp4',
  'application/octet-stream',
];

export const documentTypes = [
  'image/svg+xml',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'text/plain',
  'application/pdf',
  'application/rar',
  'application/zip',
  'application/7z',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.android.package-archive',
];
