<template>
  <Item
    :name="$t('messenger.archived_chats')"
    :text="archivedChatsPreview"
    :avatar="require('@/assets/archive-icon.svg')"
    hide-info
  />
</template>

<script>
import Item from './Item.vue';

export default {
  components: {
    Item,
  },
  props: {
    archivedChats: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    archivedChatsPreview() {
      return this.archivedChats.map((chat) => chat.name).join(', ');
    },
  },
};
</script>
