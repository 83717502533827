<template>
  <div class="bar">
    <transition
      name="mode-transition"
      mode="out-in"
    >
      <Normal
        v-if="selectedMode === 'normal'"
        :selected-category="selectedCategory"
        @selectCategory="$emit('selectCategory', $event)"
      />
      <Search
        v-else-if="selectedMode === 'search'"
        @search="$emit('search', $event)"
        @exit="$emit('selectMode', 'normal')"
      />
      <Archived
        v-else-if="selectedMode === 'archived'"
        @exit="$emit('selectMode', 'normal')"
      />
    </transition>

    <button
      class="open-search"
      :class="{ hidden: selectedMode === 'search' }"
      @click="$emit('selectMode', 'search')"
    >
      <SearchIcon />
    </button>
  </div>
</template>

<script>
import SearchIcon from '@/components/icons/Search.vue';

import Archived from './Archived.vue';
import Normal from './Normal.vue';
import Search from './Search.vue';

export default {
  emits: ['search', 'selectCategory', 'selectMode'],
  components: {
    Normal,
    Search,
    Archived,
    SearchIcon,
  },
  props: {
    selectedCategory: {
      type: String,
      default: 'dm',
    },
    selectedMode: {
      type: String,
      default: 'normal',
    },
  },
};
</script>

<style lang="scss" scoped>
.bar {
  position: relative;
  box-sizing: border-box;
  background: white;
  z-index: 10;
  padding: 12px;
  padding-top: calc(12px + env(safe-area-inset-top));
  padding-right: calc(12px + 26px);
  height: 52px;
  width: 100%;
  overflow: hidden;

  .open-search {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .mode-transition-enter-active {
    transition: opacity .3s, transform .3s;
  }

  .mode-transition-leave-active {
    transition: opacity .3s, transform .3s;
  }

  .mode-transition-enter {
    pointer-events: none;
    transform: translateX(100%);
  }

  .mode-transition-leave-to {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%);
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .bar {
    top: 0;
    width: 100%;
  }
}
</style>
