import { imageTypes, videoTypes, documentTypes } from '@/views/Messenger/ChatBody/MessageInput/acceptedFiletypes';

import FileAttachment from './FileAttachment';
import ImageAttachment from './ImageAttachment';
import VideoAttachment from './VideoAttachment';

export default async function attachmentFactory(file, i) {
  if (imageTypes.filter((t) => file.type.includes(t)).length === 1) {
    const attachment = new ImageAttachment(file, i);
    return attachment.init();
  }

  if (videoTypes.filter((t) => file.type.includes(t)).length === 1) {
    const attachment = new VideoAttachment(file, i);
    return attachment.init();
  }

  const attachment = new FileAttachment(file, i);
  return attachment.init();
}
