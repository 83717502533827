<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 300,
    }"
    class="wrapper"
    :class="{ own }"
  >
    <slot />

    <div class="pre-msg">
      <div
        v-if="firstUnread"
        class="unread-line"
      >
        <hr>
        <span>
          {{ $t('messenger.unread') }}
        </span>
        <hr>
      </div>
    </div>

    <router-link
      v-if="showSender"
      class="avatar-link"
      :to="message.author.link"
    >
      <img
        :src="message.author.avatar"
        class="avatar"
      >
    </router-link>

    <div
      class="message"
      :class="{
        firstUnread,
        selected,
        highlighted,
        'emoji-only': emojiOnly,
      }"
      @contextmenu.prevent="openActions"
      @click="handleClick"
    >
      <router-link
        v-if="showSender"
        :to="message.author.link"
        class="sender"
      >
        {{ message.author.name }}
      </router-link>

      <Actions
        ref="actions"
        :message="message"
        :messages-rect="messagesRect"
        :chat="selectedChat"
        @delete="$emit('delete')"
        @forward="$emit('forward')"
        @closed="handleActionsClose"
      />
      <Attachments
        v-if="!noMedia"
        :message="message"
        class="attachments"
      />
      <!-- eslint-disable -->
      <!-- Вроде как vue-highlights защищен от xss -->
      <div
        class="text"
        :class="{ inline: short && noMedia && !emojiOnly }"
        v-html="highlightedMessage"
      />
      <!-- eslint-enable -->
      <div
        class="mini-info"
        :class="{ inline: short && noMedia && !emojiOnly }"
      >
        <EditIcon
          v-if="message.edited"
          class="edited-icon"
        />
        <ReadIcon v-if="message.read" />
        <UnreadIcon v-else />
        {{ sentTime }}
      </div>

      <div
        v-if="message.unconfirmed"
        class="unconfirmed"
      >
        <ClockIcon />
      </div>
    </div>

    <div
      v-if="highlighted"
      class="search-result-text"
    >
      {{ $t('messenger.search_result') }}
    </div>
  </div>
</template>

<script>
import { autoLink } from 'vue-highlights';
import { mapState, mapActions } from 'vuex';

import formatDateTime from '@/mixins/formatDateTimeMixin';

import ClockIcon from '@/components/icons/Clock.vue';
import EditIcon from '@/components/icons/Edit.vue';
import ReadIcon from '@/components/icons/Read.vue';
import UnreadIcon from '@/components/icons/Unread.vue';

import highlightOptions from '@/components/highlightOptions';

import Actions from './Actions/Index.vue';
import Attachments from './Attachments/Index.vue';

import Chat from '@/models/Chat';
import messageFromBackend from '@/models/dto/messageFromBackend';
import Message from '@/models/Message';

export default {
  emits: ['contextMenuOpen', 'contextMenuClosed'],
  name: 'Message',
  components: {
    ReadIcon,
    UnreadIcon,
    EditIcon,
    ClockIcon,
    Attachments,
    Actions,
  },
  mixins: [formatDateTime],
  props: {
    msg: {
      type: Object,
      required: true,
    },
    firstUnread: {
      type: Boolean,
      default: false,
    },
    selectedChat: {
      type: Chat,
      default: () => {},
    },
    messagesRect: {
      // type: DOMRect,
      type: null,
      required: true,
    },
  },
  data() {
    const noMedia = !this.msg.video && !this.msg.audio
        && !this.msg.model && !this.msg.image
        && !this.msg.file && !this.msg.replied_data
        && !this.msg.round && !this.msg.fwd_data
        && !this.msg.geo;

    const emojiOnlyViable = !this.msg.video && !this.msg.audio
        && !this.msg.model && !this.msg.image
        && !this.msg.file;

    let message;
    if (this.$route.params.chatId === 'jinny') {
      message = messageFromBackend({
        author: {
          type: 'user',
          id: this.msg.author_id,
        },
        ...this.msg,
      });
    } else {
      message = messageFromBackend(this.msg);
    }

    return {
      noMedia,
      emojiOnlyViable,
      noAttachments: noMedia && !this.msg.call && !this.msg.geo,
      short: this.msg.text && this.msg.text.length < 30 && !this.msg.text.includes('\n'),
      message,
      selected: false,
    };
  },
  computed: {
    ...mapState([
      'selectedMessageId',
    ]),
    msgSocket() {
      return this.$store.state.api.msgSocket;
    },
    searchResultId() {
      return this.$route.query.mid;
    },
    own() {
      return this.message.author.id === this.$store.getters.profile.id;
    },
    showSender() {
      return !this.own && this.selectedChat?.type === 'group';
    },
    sentTime() {
      if (this.$route.params.chatId === 'jinny') {
        return '';
      }
      return this.formatTime(this.message.createdAt);
    },
    highlightedMessage() {
      return autoLink(this.message.text, highlightOptions);
    },
    searchResult() {
      return this.message.id && this.searchResultId === this.message.id;
    },
    highlighted() {
      return !this.firstUnread && (this.searchResult || this.selectedMessageId === this.message.id);
    },
    emojiOnly() {
      const regex = /\P{Extended_Pictographic}/ug;
      const trueLength = [...this.message.text].length;
      return !regex.test(this.message.text)
        && this.emojiOnlyViable
        && trueLength <= 3
        && trueLength > 0;
    },
  },
  mounted() {
    this.msgSocket.addEventListener('message', this.handleResponse);
    this.mobile = window.innerWidth <= this.$scss.mobileBreakpoint;
  },
  methods: {
    ...mapActions(['readMessages']),
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.readMessages({ message: this.msg, firstUnreadId: this.selectedChat.firstUnreadId });
      } else if (this.$route.query.mid === this.message.id) {
        this.$router.replace({ query: null });
      }
    },
    messageUpdate(message) {
      if (message.id === this.message.id) {
        this.message = messageFromBackend(message);
      }
    },
    handleResponse(response) {
      if (!response.data) return;

      const handlers = {
        message_update: this.messageUpdate,
      };

      const action = JSON.parse(response.data);
      const actionName = Object.keys(action)[0];

      setTimeout(() => {
        handlers[actionName]?.(action[actionName]); // eslint-disable-line 
      });
    },
    handleClick(event) {
      if (this.mobile) {
        this.openActions(event);
      }
    },
    openActions(event) {
      setTimeout(() => {
        this.selected = true;
        this.$refs.actions.open(event);
        this.$emit('contextMenuOpen');
      });
    },
    handleActionsClose() {
      this.selected = false;
      this.$emit('contextMenuClosed');
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  display: flex;
  $radius: 20px;
  position: relative;

  &.own {
    flex-direction: row-reverse;

    .message {
      border-radius: $radius $radius 0 $radius;
      background: $clr-own-bubble;
      position: relative;

      &.emoji-only {
        .attachments {
          background: $clr-own-bubble;
        }
      }

      .mini-info {
        color: var(--clr-brand);

        svg {
          display: inline;
        }
      }

      .unconfirmed {
        display: block;
        position: absolute;
        left: -4px;
        bottom: -4px;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        background: $clr-20;
        padding: 1px;
        box-sizing: border-box;
      }
    }
  }

  .message, .avatar-link {
    margin-bottom: 12px;
  }

  .avatar-link {
    display: flex;
    align-items: flex-end;

    .avatar {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin: 8px 8px 0 0;
    }
  }

  .message {
    position: relative;
    border-radius: $radius $radius $radius 0;
    border: 1px solid $clr-border-middle;
    padding: 8px 0;
    font: $font-rn-300;
    background: $clr-their-bubble;
    width: fit-content;
    min-width: 100px;
    max-width: 70%;
    transition: box-shadow .4s;

    .unconfirmed {
      display: none;
    }

    .sender {
      display: block;
      font: $font-rn-200;
      color: var(--clr-brand);
      padding: 0 16px;
      // margin-bottom: 4px;
    }

    &.selected {
      box-shadow: 0px 2px 8px rgba(8, 11, 48, 0.2);
    }

    &.highlighted {
      box-shadow: 0px 2px 8px rgba(8, 11, 48, 0.2);
    }

    &.firstUnread {
      margin-top: 32px;
    }

    &.emoji-only {
      background: none;
      border: none;

      .text {
        vertical-align: middle;
        font-size: 64px;
        line-height: 72px;
      }

      .attachments {
        border: 1px solid $clr-border-middle;
        background: $clr-their-bubble;
        border-radius: $radius;
        padding: 8px 0;
      }
    }

    .text, .mini-info {
      padding: 0 16px;
    }

    .text {
      white-space: pre-wrap;
      word-break: break-word;

      &.inline {
        display: inline-block;
      }
    }

    .mini-info {
      color: $clr-gray;
      font: $font-rn-200;
      text-align: right;

      &.inline {
        display: inline-block;
        margin-left: -16px;
      }

      svg {
        color: inherit;
        display: none;
      }

      .edited-icon {
        color: $clr-text-trietary;
        margin-right: 4px;
        width: 8px;
        height: 8px;
      }
    }
  }

  .search-result-text {
    margin: 8px 16px;
    font: $font-rn-200;
    color: $clr-text-trietary;
  }

  .pre-msg {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;

    .unread-line {
      width: 100%;
      display: flex;
      align-items: stretch;
      margin-top: 16px;

      hr {
        height: 1px;
        border: none;
        background-color: $clr-border-dark;
        flex-grow: 1;
      }

      span {
        font: $font-rn-200;
        color: $clr-text-trietary;
        margin: 0 12px;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .wrapper {
    .message {
      max-width: 300px;
    }
  }
}
</style>
