<template>
  <div class="search-field">
    <input
      v-model="query"
      placeholder="Поиск в чатах"
      @input="search"
    >
    <img
      src="~@/assets/icons/times.svg"
      class="close btn"
      @click="exit"
    >
  </div>
</template>

<script>
import api from '@/api';

export default {
  emits: ['exit', 'search'],
  data() {
    return {
      query: '',
    };
  },
  methods: {
    async search() {
      const result = await api.messenger.searchMessages({ query: this.query });
      this.$emit('search', result);
    },
    exit() {
      this.query = '';
      this.$emit('exit');
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  position: absolute;
  width: calc(100% - 24px);
  border-radius: 16px;
  background: $clr-20;

  input {
    border: none;
    width: calc(100% - 36px);
    padding: 6px 12px;
    box-sizing: border-box;
    background: none;
    font: $font-rn-400;
    right: 16px;
  }

  .close {
    position: absolute;
    padding: 12px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
