import Attachment from './Attachment';

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export default class VideoAttachment extends Attachment {
  constructor(file, i) {
    super(file, i);
    this.component = 'video';
  }

  async init() {
    this.thumb = await readFileAsync(this.file);
    return this;
  }
}
